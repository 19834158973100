<template>
  <div 
    id="search-product-list"
    class="search-product-list"
    :da-expose-code="PageState.exposeCode"
  >
    <template v-if="PageState.ready">
      <!-- 顶部沉浸式背景 -->
      <TopBackground
        v-if="!!topBackgroundParam"
        :type="topBackgroundParam.name"
        :change-header-bg="topBackgroundParam.changeHeaderBg"
        :scroll-transparent="topBackgroundParam.scrollTransparent"
        :extend-data="topBackgroundParam?.extendData"
      />
      <!-- search only -->
      <SearchCard
        v-if="hasSearchBannerCard"
        :searchStoreCard="searchStoreCard"
        :searchTrendCard="searchTrendCard"
        :searchCCCCard="searchCCCCard"
        :list-abt-result="listAbtResult"
        :language="language"
        :locals="locals"
        :shein-club-info="sheinClubInfo"
        :expose-code="PageState.exposeCode"
        :fashion-bg="topBackgroundParam?.extendData?.img"
        :constant-data="constantData"
        :goods-num="goods?.length || 0"
        :cat-info="catInfo"
        :style="{
          paddingBottom: searchStoreCard && Array.isArray(picTopNav) && picTopNav.length > 1 ? '0' : ''
        }"
        @subscribeExpose="onSubscribeExpose"
      />
      <ListTopInfo
        v-if="ComponentState.ListTopInfo"
        ref="ListTopInfo"
        :search-suggest-new="isSearchSuggest"
        :interception="interception"
        :is-suggest-correction="abtValue.isSuggestCorrection && goods.length !== 0 "
        :has-correction-word="hasCorrectionWord"
      />
      <!-- v.趋势搜索结果页屏蔽该功能 -->
      <!-- 图文导航 -->
      <PicTopNav
        v-if="!isTopTrendResultPage && !shouldHidePicTopNavBySearchCardAbt"
        v-show="filterBarDisPlay && isShowPicTopNav"
        ref="refPicTopNav"
        :locals="locals"
        :list="picTopNav"
        :filter-bar="filterBar"
        :query-params="queryParams"
        :language="language"
        :cat-info="catInfo"
        :list-abt-result="listAbtResult"
        :loading="isReflesh"
        :constant-data="constantData"
        :has-search-banner-card="hasSearchBannerCard"
        :tab-bg-color="ptnTabBgColor"
        :has-correction-word="hasCorrectionWord"
        @change="onPicTopNavChange" 
      />
      <!-- 筛选栏 -->
      <NavBar 
        v-show="filterBarDisPlay"
        ref="refNavBar"
        :locals="locals"
        :list-abt-result="listAbtResult"
        :filter-bar="filterBar"
        :query-params="queryParams"
        :language="language"
        :cat-info="catInfo"
        :sum="closeBottomLeft ? 0 :sum"
        :pic-top-nav="picTopNav"
        :sort-map="sortMap"
        :sort-map2="sortMap2"
        :sort-all-list="sortAllList"
        :loading="isReflesh"
        :close-bottom-left="closeBottomLeft"
        :fh-context="fhContext"
        :constant-data="constantData"
        :google-context="googleContext"
        @change="onPicNavChange"
        @saveCurrAttr="saveCurrAttr"
      />
      <!-- 标签云 -->
      <CloudTags
        v-show="filterBarDisPlay"
        ref="refCloudTags"
        :locals="locals"
        :list-abt-result="listAbtResult"
        :filter-bar="filterBar"
        :query-params="queryParams"
        :language="language"
        :pic-top-nav="picTopNav"
        :cat-info="catInfo"
        :sum="closeBottomLeft ? 0 : sum"
        :close-bottom-left="closeBottomLeft"
        :loading="isReflesh"
        :fh-context="fhContext"
        :banner-tag="bannerTag"
        :google-context="googleContext"
        :constant-data="constantData"
        :is-spa-page="PageState.isSpaPage"
        :is-second-load="isNoProductsInFirstLoad"
        :is-default-kid-toast="isDefaultKidToast"
        :is-show-kid-popover="filterBarDisPlay && !scrollDisabled"
        :is-show-age-limit-dialog="isShowAgeLimitDialog"
        @change="onCloudTagsChange"
        @toStickyByClick="toStickyByClick"
        @saveCurrAttr="saveCurrAttr"
      />
      <!-- 一键购标签 -->
      <OneClickPayTag 
        v-if="filterBarDisPlay"
        ref="refOneClickPayTag"
        :cat-info="catInfo"
        :loading="isReflesh"
        :filter-bar="filterBar"
        :banner-tag="bannerTag"
        :constant-data="constantData"
        :query-params="queryParams"
        :language="language"
        :fh-context="fhContext"
        :google-context="googleContext"
        @change="onOneClickPayChange"
      />
      <!-- 政策条 -->
      <PolicyBanner2
        v-if="!(bannerTag && bannerTag.showTag) && filterBarDisPlay && policyBannerConfig.content.length"
        ref="refPolicyBanner"
        :cat-info="catInfo"
        :filter-bar="filterBar"
        :policy-banner-config="policyBannerConfig"
      />
      <template v-if="showPageRenderDone">
        <!-- 搜索置顶券 - 挂载点 search only -->
        <div id="j-coupon-banner-top"></div>
        <!-- 列表 -->
        <div
          v-show="goods.length"
          v-infinite-scroll="loadMore"
          v-infinite-scroll-distance="PageState.infiniteScrollDistance"
          infinite-scroll-disabled="scrollDisabled"
          infinite-scroll-nodata="noMoreProductsData"
          infinite-scroll-window="true"
        >
          <ProductList 
            ref="ProductList"
            :change-recommend-num="changeRecommendNum || 0"
            :no-more-data="noMoreProductsData"
            :cur-page-refresh="isRefresh"
            :search-feedback-visibility="!!(goods.length > 0 && ComponentState.SearchFeedback)"
            :show-skeleton="PageState.searchPageLoading || PageState.skeletonLoading"
            :report-metrics="reportMetrics"
    
            :add-recommend-product-handle="addRecommendProductHandle"
            :local-index="localIndex"
    
            :getClickRefreshAbt="getClickRefreshAbt"
            :removeUnexposedGoods="removeUnexposedGoods"
            :triggerComeBackRefreshList="triggerComeBackRefreshList"
            :resolveDelayResolveNewData="resolveDelayResolveNewData"
            :openDetectInactivity="Boolean(apolloConfig.DETECT_INACTIVITY)"

            feature-key="click_refresh"
            :query-ts="queryTs"
            :is-top-trend-result-page="isTopTrendResultPage"
            :one-click-pay-guide-dialog-show="oneClickPayGuideDialogShow"
            @clickGoods="handleClickGoods"
            @operateInRecPopup="handleOperateInRecPopup"
            @oneClickPayComplete="handleOneClickPayComplete"
            @quickAddSuccess="handleQuickAddSuccess"
            @clickStartAddQuick="handleClickStartAddQuick"
            @ageLimitDialogChange="onAgaLimitDialogChange"
            @exposedCard="handleExposedCard"
          />
          <!-- 店铺搜索少结果（只有1-4个商品时），底部提示 -->
          <NoMoreResultsTips v-if="isStoreSearch && goods.length < 5 && !Request.type" />
        </div>
        <FilterResult
          v-if="isShowFilterResult"
          ref="refFilterResult"
          :sum="sum"
          :cat-info="catInfo"
          :filter-bar="filterBar"
          :language="language"
          :pic-top-nav="picTopNav"
          :query-params="queryParams"
          :has-filter="PageState.hasFilt"
          @change="onFilterResultChange" 
          @reselect="openSideFilter"
        />
        <ClientOnly>
          <!-- 上位词搜索到的商品列表 search only -->
          <!-- v.趋势搜索结果页屏蔽该功能 -->
          <HypernymProductWrap
            v-if="hypernymProductInfo.list.length && sum <= 200"
            ref="HypernymProductWrap"
            :info="hypernymProductInfo"
            :search-goods-sum="goods.length"
            @loadMoreHypernymGoods="loadMoreHypernymGoods"
            @hypernymProductMounted="initHypernymListExpose"
            @dropCart="dropCart"
            @oneClickPayComplete="handleOneClickPayComplete"
          />
        </ClientOnly>
        <!-- 店铺搜索，趋势搜索 空态页 -->
        <!-- v.趋势搜索结果页屏蔽该功能 -->
        <StoreNoResult
          v-if="showNoResult && (isNewStoreNoResult || isTopTrendResultPage)"
          class="fsp-element"
          :keyword="searchKeywords.keywords"
        />
        <!-- 主搜列表页，专题搜索页 空态页 -->
        <NoResult
          v-else-if="showNoResult && !isShowFilterResult"
          class="fsp-element"
          :has-rec-content="hasRecContent"
          :config="ComponentState.NoResult"
          :keyword="searchKeywords.keywords"
          :search-related-state="ComponentState.SearchRelated"
          :search-feedback-state="ComponentState.SearchFeedback"
          :cat-info="catInfo"
          :search-keywords="searchKeywords"
          :is-top-trend-result-page="isTopTrendResultPage"
          @searchInAll="searchInAll"
          @reselect="openSideFilter"
        />
      </template>
    </template>
    <ClientOnly>
      <ListSkeletonV3
        v-if="rootSLoading.show"
        page="productList"
      />
      <!-- 搜索跳转进来第一次需要显示page loading -->
      <template v-if="!PageState.searchPageLoading">
        <!-- <ListSkeletonV2
          v-if="PageState.skeletonLoading"
          page="productList"
        /> -->

        <!-- firstload用searchPageLoading -->
        <ListLoaders
          v-if="(Request.type || hypernymProductInfo.loading) !== 'firstload'"
          :pageleave="PageState.pageleave"
          :request-type="Request.type || hypernymProductInfo.loading"
        />  
      </template>
      <!-- 反馈 search only -->
      <SearchFeedback
        v-if="goods.length > 0 && ComponentState.SearchFeedback"
        ref="SearchFeedback"
        :float-type="true"
        :locals="locals"
        :state="ComponentState.SearchFeedback"
        :list-abt-result="listAbtResult"
        :cat-info="catInfo"
        :search-keywords="searchKeywords"
        :is-top-trend-result-page="isTopTrendResultPage"
      />
      <!-- 底部推荐列表（全局搜索，专题搜索，店铺搜索，趋势搜索） -->
      <RecommendList 
        :show-no-result="showNoResult"
        :no-more-products-data="noMoreProductsData"
        :hypernym-product-info="hypernymProductInfo"
        :interception="interception"
        @hasRecGoods="hasRecContent = true" 
      />
      <!-- back to top 全局搜索有推荐位就要展示 -->
      <!-- v.趋势搜索结果页屏蔽该功能 -->
      <ListBottomInfo
        v-if="(goods.length > 8 || hasRecContent) && ComponentState.ListBottomInfo && noMoreProductsData && !isTopTrendResultPage"
        :context="ComponentState.ListBottomInfo"
        @subscribeExpose="onSubscribeExpose"
      />
    </ClientOnly>
    <!-- 搜索吸底券 - 挂载点 -->
    <div id="j-coupon-banner-bottom"></div>
  </div>
</template>
<script>
import { defineComponent, nextTick, defineAsyncComponent } from 'vue'
import infiniteScroll from '@sheinfe/vue-infinite-scroll'
// Vue
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { storeModuleName } from '@/public/src/pages/product_app/store/modules/search-product-list/index.js'

// components
import { ClientOnly } from '@sheinfe/vue-client-only'
import TopBackground from '@/public/src/pages/product_list_v2/components/TopBackground/Index.vue'
import ListTopInfo from '@/public/src/pages/product_list_v2/components/ListTopInfo.vue'
import SearchCard from 'public/src/pages/product_list_v2/components/SearchCard/index.vue'
import ProductList from '@/public/src/pages/product_list_v2/components/ProductList'

// mixins
import filterBarMixins from '@/public/src/pages/components/FilterBar/mixin/filterBarMixins'
import storeMixins from '@/public/src/pages/product_list_v2/js/storeMixins'
import provideMixins from '@/public/src/pages/product_list_v2/js/provideMixins'
import routeMixins from '@/public/src/pages/product_list_v2/js/routeMixins'
import refreshListStrategy from '@/public/src/pages/product_list_v2/js/refreshListStrategy'
import dynamicRecommendProductMixin from '@/public/src/pages/product_list_v2/js/dynamicRecommendProductMixin.js' // 泛列表点后推mixins
import contantDataMixin from './mixins/constantDataMixin'
import noResultMixin from './mixins/noResultMixin'
import imgOptimizeMixin from './mixins/imgOptimizeMixin'
import buyBoxPriceMixin from '@/public/src/pages/product_list_v2/js/buyBoxPriceMixin.js'

// abt & functional & analysis
import { isNumber } from '@shein/common-function'
import { abortRequest, requestProductListApi } from '@/public/src/pre_requests/modules/productList/index.js'
import BffAdapter from '@/public/src/pages/product_list_v2/js/bffAdapter'
import { 
  initPrefetchResource, 
  startResolveData,
  filterDataQueue,
  setMarkPoint,
  clickGoods,
  handleOperateInRecPopup,
  handleOneClickPayComplete,
  handleClickStartAddQuick,
  onSubscribeExpose,
  setFeedbackRecInfo,
  hideFeedbackRecPopup,
  doSearchPreprocess,
  setSilInMeta,
  initHypernymProductInfo,
  hypernymProductInfo,
  handleHypernymFetch,
  fetchHypernyData,
  initHypernymListExpose,
  refreshAnalysisData,
  updatePageData,
  initExposeAnalysis,
  isNoProductsInFirstLoad,
  isDefaultKidToast,
  handleKidsCloudTag,
  resetKidsCloudTagsVariable,
  handleCheckoutBtn,
  checkoutGoodsInfo,
  handleChekcoutBtnBackFromDetail,
  offlineHanler,
  setUserActionsCustomData,
} from './hooks/index.js'
import { FIRST_LOAD, NEXT_PAGE, REFRESH } from './hooks/useConstant.js'
import { monitorPageOpen, isMarketingPage } from 'public/src/pages/common/business-monitor/products.js'  
import { getDefaultKidParamsByAb } from 'public/src/pages/components/FilterBar/utils/kids.js'
import { checkIsBackFromDetail, getPageTypeByRoute, isServer } from '@/public/src/pages/product_list_v2/js/utils.js'
import { emitReEnterInList } from '@/public/src/pages/mounting/eventBus/eventLib/eventEmit.js'
import { onClickProductCardInGoodsDetail } from 'public/src/pages/mounting/eventBus/eventLib/eventOn'
import { offClickProductCardInGoodsDetail } from '@/public/src/pages/mounting/eventBus/eventLib/eventOff'
import addSubscriberPage from '@/public/src/pages/product_list_v2/analytics/subscriberPage.js'
// import page from '../checkout_new/store/state/page'
import infiniteScrollDistance from 'public/src/pages/common/fixedInfiniteScrollDistanceAfterSSR.js'
import Mutex from './helpers/Mutex'

// let fromRouteName = '' // 页面来源，路由名
export default defineComponent({
  name: 'SearchProductListContainer',
  components: {
    ClientOnly,
    TopBackground,
    SearchCard,
    RecommendList: defineAsyncComponent(() => import(/* webpackChunkName: "sl_Recommend" */'./components/RecommendList.vue')),
    // ListSkeletonV2: () => import(/* webpackChunkName: "sl_ListSkeletonV2" */'../product_list_v2/components/ListSkeletonV2/index.vue'),
    ListSkeletonV3: defineAsyncComponent(() => import(/* webpackChunkName: "sl_ListSkeletonV3" */'./components/ListSkeletonV3')),
    ListLoaders: defineAsyncComponent(() => import(/* webpackChunkName: "sl_ListLoaders" */'../product_list_v2/components/ListLoaders.vue')),
    HypernymProductWrap: defineAsyncComponent(() => import(/* webpackChunkName: "sl_HypernymGoods" */ './components/HypernymProductWrap.vue')),
    SearchFeedback: defineAsyncComponent(() => import(/* webpackChunkName: "sl_SearchFeedback" */'../product_list_v2/components/SearchFeedback.vue')),
    NoMoreResultsTips: defineAsyncComponent(() => import(/* webpackChunkName: "sl_NoMoreResultsTips" */'./components/NoMoreResultsTips.vue')),
    ListBottomInfo: defineAsyncComponent(() => import(/* webpackChunkName: "sl_ListBottomInfo" */'public/src/pages/product_list_v2/components/ListBottomInfo.vue')),
    ProductList: storeMixins(ProductList, {
      ...mapState(storeModuleName, ['ComponentState']),
      ...mapGetters(storeModuleName, [
        'locals', 'goods', 'appendGoods', 'catInfo', 'language',
        'searchKeywords', 'listAbtResult', 'cccConfig', 'flowMap', 'sheinClubInfo',
      ]),
    }),
    ListTopInfo: storeMixins(ListTopInfo, {
      ...mapState(storeModuleName, ['ComponentState']),
      ...mapGetters(storeModuleName, [
        'locals',
        'language',
        'sum',
        'catInfo',
        'currentCat',
        'parentCats',
        'searchKeywords',
      ]),
    })
  },
  directives: {
    infiniteScroll,
    infiniteScrollDistance,
  },
  mixins: [
    contantDataMixin,
    provideMixins,
    dynamicRecommendProductMixin, // 点推
    refreshListStrategy({ storeModuleName }), // 点刷
    filterBarMixins({ storeModuleName }), // 筛选
    noResultMixin,
    imgOptimizeMixin,
    buyBoxPriceMixin
  ],
  asyncData({ store, context, from = {} }) {
    const isSsrPage = !from.name
    if (isSsrPage) {
      if (context?.cat_info?.type) {
        store.state[storeModuleName].context = context
      }
    }
  },
  data() {
    return {
      storeModuleName,
      isRefresh: false, // 是否筛选
      Request: {
        fullpath: '', // url路径
        type: '', // 请求时机 首次: firstload, 筛选: refresh, 翻页: nextpage
        query: { // query参数
          page: 1,
          limit: 10,
        },
      },
      reportMetrics: { // 业务监控配置
        img: true, 
        addBag: true, 
        jumpDetail: true, 
        clickCartBag: true 
      },
      queryTs: '', // interleaving的queryId
      showPageRenderDone: false,
      hypernymProductInfo,
      hasRecContent: false,
      isNoProductsInFirstLoad, // 首次加载无商品[记录第一次默认童装筛选，无商品状态]
      isDefaultKidToast, // 是否默认童装toast弹出
      // goodsLoading: false
    }
  },
  computed: {
    ...mapState(['vuex_module_registing', 'cartInfo', 'rootSLoading']),
    ...mapState(storeModuleName, ['PageState', 'ComponentState', 'oneClickPayGuideDialogShow']),
    ...mapGetters(storeModuleName, [
      'locals',
      'language',
      'listAbtResult',
      'cccSeo',
      'catInfo',
      'currentCat',
      'parentCats',
      'searchKeywords',
      'sum',
      'goods',
      'tracking',
      'request_ext',
      'goodsCrowId',
      'fhContext',
      'googleContext',
      'searchStoreCard',
      'searchTrendCard',
      'searchCCCCard',
      'listFilterAbt',
      'policyBanner',
      'bannerTag',
      'sheinClubInfo',
      'hasSearchBannerCard',
      'topBackgroundParam',
      'ptnTabBgColor',
      'hasCorrectionWord',
      'isTopTrendResultPage',
      'apolloConfig',
      'isBlockingPointPush',
    ]),
    // 主列表商品数据 到底了 || 为空
    noMoreProductsData() {
      return this.PageState?.noMoreData || false
    },
    abtValue() {
      return {
        isSuggestCorrection: this.listAbtResult?.searchcorrect?.p?.correction_type === 'suggestcorrection'
      }
    },
    // infinite-scroll-disabled 只接受一级变量
    scrollDisabled() {
      return this.PageState.landingLock || this.Request.type
    },
    // 店铺搜索结果页
    isStoreSearch() {
      return this.$route.query?.search_type == 'store'
    },
    interception() {
      return this.PageState.pageleave || this.vuex_module_registing
    },

    filterBarDisPlay() {
      return !!(
        this.goods.length || 
        // (!this.goods.length && this.goodsLoading) || 
        this.PageState.hasFilt
      )
    },
    // search only
    isSearchSuggest() {
      // this.listAbtResult?.NoResultPage?.p?.NoResultPage === 'new' 推全 'new分支'
      return this.ComponentState?.ListTopInfo?.showPostCorrection === 1 
    },
    // 政策条
    policyBannerConfig() {
      const { language = {}, content = {}, isBff } = this.policyBanner || {}
      return {
        isBff,
        context: {
          lang: this.lang,
          language,
          PUBLIC_CDN: this.PUBLIC_CDN,
          ...this.locals
        },
        // 如果有账号可用券 隐藏福利政策
        content: content?.content || [],
        sceneData: {
          pageFrom: 'list',
          id: content?.id || '',
          pageType: 'commonListPage',
          sceneName: 'all'
        }
      }
    },
    // 是否展示图片导航；与搜索卡片互斥
    shouldHidePicTopNavBySearchCardAbt() {
      const searchCardMutual = this.listAbtResult?.searchcardmutual
      const shouldHidePicTopNavBySearchShopBanner = searchCardMutual?.p?.storesearchcardmutual === 'yes' && Boolean(this.searchStoreCard?.length)
      const shouldHidePicTopNavBySearchTrendCard = searchCardMutual?.p?.trendsearchcardmutual === 'yes' && Boolean(this.searchTrendCard)
      const shouldHidePicTopNavBySearchCCCCard = searchCardMutual?.p?.noitemsearchcardmutual === 'yes' && Boolean(this.searchCCCCard)

      // 三个条件中某一个命中就隐藏图文导航
      return [
        shouldHidePicTopNavBySearchShopBanner,
        shouldHidePicTopNavBySearchTrendCard,
        shouldHidePicTopNavBySearchCCCCard
      ].some(Boolean)
    },
    // 请求状态(xhr: logicBeforeRequest中赋值，ssr: hooks hydrationInitRequest中赋值，goodsLoadedOnPageList中清空)
    curRequestType() {
      return {
        isFirstLoad: this.Request.type === FIRST_LOAD,
        isRefresh: this.Request.type === REFRESH,
        isNextPage: this.Request.type === NEXT_PAGE,
      }
    },
  },
  // 跳到新搜索列表（图文转query，锦囊，分布式筛选） || 筛选
  beforeRouteUpdate(to, from, next) {
    // @todo [x] hacker写法，因为vue3从mixins引入不会执行beforeRouteUpdate
    routeMixins.beforeRouteUpdate(to, from, () => {
      // 搜索场景下，多tab图文转query，点击图文跳转到新搜索列表。回退后仅触发 update，不会触发 routerEnter
      sessionStorage.removeItem('picTopNavTabId') // 如果不是从详情页返回，清除 tab_id
      next()
      hideFeedbackRecPopup(this) // 隐藏实时反馈弹窗
      if (this.curRequestType.isRefresh) { // 筛选
        this.Request.fullpath = this.getDecodeFullPath(to)
      } else { // 非筛选外，路由的变化进这里（图文转query，锦囊，分布式筛选）
        abortRequest()
        this.updateNewSrcTabPageId() // 更新tab_page_id
        this.setPageState({ isSpaPage: true })
        this.switchListPage() // 关闭筛选面板和取消滚动展开筛选(定义在filterBarMixins)
        setTimeout(() => this.spaFirstIn({ ...to, fullPath: this.getDecodeFullPath(to) }), 50)
      }
    })
  },
  beforeRouteEnter(to, from, next) {
    // @todo [x] hacker写法，因为vue3从mixins引入不会执行beforeRouteEnter
    routeMixins.beforeRouteEnter(to, from, () => {
      if (isServer()) return next()
      const isBackFromDetail = checkIsBackFromDetail(from.path)
      // fromRouteName = from.name // 存当前路由名，给后面需要的地方用
      
      if (!isBackFromDetail) {
        // 如果不是从详情页返回，清除 tab_id
        sessionStorage.removeItem('picTopNavTabId')
      }
      
      next(async (vm) => {
        vm.dealClickRefresh(
          from, 
          () => {
            // 这里处理列表进商详，商详再回来此时goods数据keep-alive的
            // 商详返回时，激活实时反馈弹窗
            setFeedbackRecInfo({ productListInstance: vm.$refs.ProductList, path: from.path })
            handleChekcoutBtnBackFromDetail(vm, { isBackFromDetail })
            emitReEnterInList({ isBackFromDetail })
            // 如果 from 有 name, 说明是 SPA 页内跳转, 而非直接进入页面
            // 如果 from 没有 name, 说明是直接进入的页面，走的 SSR, 而不是 SPA 页内跳转
            vm.PageState.isSpaPage = Boolean(from.name)
            to.path !== from.path && vm.switchListPage() // 关闭筛选面板和取消滚动展开筛选(定义在filterBarMixins)

            // 从购物车进入列表
            if (from.name === 'cart') {
              vm.handleFromCart && vm.handleFromCart()
            }   

            vm.handleEnterProductList()
          }
        )
      })
    })
  },
  async beforeRouteLeave(to, from, next) {
    // @todo [x] hacker写法，因为vue3从mixins引入不会执行beforeRouteLeave
    routeMixins.beforeRouteLeave(to, from, () => {
      this.switchListPage()
      // 必须放beforeRouteLeave，放deactivated，当从侧边导航点击跳转到列表页时，因为预请求比较早，所以列表的请求会被abort
      if (!this.getClickRefreshAbt('request_early')) { // 没命中点刷(定义在refreshListStrategy)
        abortRequest() // 取消请求
      }

      this.handleLeaveProductList()

      // 虽然是同一页，但是产品表达上有/无结果分别属于不同页面  search only
      const pageFrom = this.goods.length ? 'PageSearchResult' : 'PageSearchNoResult'
      this.$route.meta.pageFrom = pageFrom // 这个字段仅用于区分搜索结果页是否有结果

      next()
    })
  },
  // vue 2.6+ 新增 api, 只会在 ssr 时调用
  // 它必须返回一个 promise, 这个 promise resolve 后，才会渲染组件
  // 所以可以把 ssr 时的异步数据获取/生成逻辑放在这里
  // 但是要在 created 钩子里注意不要执行同样的逻辑, 建议 csr 的逻辑尽量放在 mounted 钩子里
  async serverPrefetch() {
    startResolveData(this) // 开始处理数据
  },
  beforeUnmount() {
    offClickProductCardInGoodsDetail()

    this.mutex = null
  },
  deactivated() {
    this.PageState.pageleave = true

    // 关闭实时反馈弹窗（”不是“点击实时反馈弹窗内部元素导致的跳转，才需要）
    hideFeedbackRecPopup(this, { factor: !this.isOperateInRecPopup })
    // 关闭loading
    this.closePageLoading()
  },
  activated() {
    this.updateNewSrcTabPageId() // 更新tab_page_id
    this.PageState.pageleave = false

    // 1. ssr
    const contextForSSR = this.$store.state[storeModuleName].context
    if (contextForSSR) {
      delete this.$store.state[storeModuleName].context
      return
    }

    const decodeFullPath = this.getDecodeFullPath(this.$route)
    // 2. 首次进入
    if (this.Request.fullpath !== decodeFullPath) {
      this.initFilterBarStyle() // 重置筛选组件(定义在filterBarMixins)
      appEventCenter?.emit?.('resetHeaderAppStickyOffset') // SPA的情况下须重置顶部栏sticky占位
      initHypernymProductInfo(this) // 重置上位词数据
      this.spaFirstIn({ ...this.$route, fullPath: decodeFullPath })
      return
    }

    // 3. 从其他页面重进上次的列表页 (例子：搜索结果页A -> 商详 -> 商详返回重进 搜索结果页A) — S
    if (!this.Request.type || this.curRequestType.isNextPage) {
      this.$store.commit('changeSeo', this.cccSeo, { root: true })
      refreshAnalysisData(this) // 重发 pv 和 商品曝光
    }
    const noClickRefresh = !this.getClickRefreshAbt('request_early_back') && !this.getClickRefreshAbt('request_early') // 没命中点刷实验(定义在refreshListStrategy)
    if (noClickRefresh) {
      if (this.Request.type) {
        // 翻页请求时，被跳转中断，返回时重新请求
        this.fetchData({ requestType: this.Request.type })
      }
    }
    // 获取一键购资格
    this.handleOneClickPayComplete()
    // 3. 从其他页面重进上次的列表页 (例子：搜索结果页A -> 商详 -> 商详返回重进 搜索结果页A) — E
  },
  created() {
    if (isServer()) return

    this.mutex = new Mutex() // 互斥锁
    this.useOwnFilterAnalysis = true // 不使用filterBarMixins,里面的筛选相关埋点（目前埋点时机不可控，不能保证埋点一定在PV后）

    startResolveData(this) // 开始处理数据
  },
  mounted() {
    // 容器已经挂载
    this.containerMounted = true

    if (isMarketingPage()) {
      this.reportMetrics.tags = { marketingStatus: '1' }
    }
    addSubscriberPage() // 埋点注册
    initPrefetchResource(this) // 预请求资源
    this.handleEventOn() // 事件注册
  },
  methods: {
    ...mapMutations(['changeRootSLoadingStatus']),
    ...mapMutations(storeModuleName, [
      'resetLocals', 
      'resetState', 
      'setPageState',
      'setBannerTagState', 
      'setGoodsCheckoutNum',
      'processDataAfterInitialLoad',
      'updateSpecificAttr'
    ]),
    ...mapActions(storeModuleName, ['initStateOrFilterDataNew', 'initProductDataNew', 'fetchComponentAsyncData']),
    getDecodeFullPath(route) {
      return decodeURIComponent(route.fullPath)
    },
    // 修改Request内的值
    modifyRequest({ key, value }) {
      if (key === 'Request') {
        this.Request = value
        return
      }
      if (key === 'query') {
        this.Request.query = {
          ...this.Request.query,
          ...value
        }
        return
      }
      this.Request[key] = value
    },

    // 请求+数据处理 - S
    // spa首次进入
    spaFirstIn({ fullPath, query }) {
      resetKidsCloudTagsVariable(this) // 重置多孩标签云的一些变量
      this.modifyRequest({
        key: 'Request', 
        value: {
          ...this.Request,
          fullpath: fullPath,
          pageType: getPageTypeByRoute(this.$route.path),
          query: {
            ...query,
            ...getDefaultKidParamsByAb(),
            limit: 10,
            page: 1,
          }
        }
      })
      this.fetchData({ requestType: FIRST_LOAD })
    },
    // 翻页
    loadMore() {
      if (this.onLineListened) return // 断网

      // 页面累加
      this.modifyRequest({ key: 'query', value: { page: this.Request.query.page + 1 } })

      // 第三页请求时，为了保证偏移正常，修改page和limit 
      const isTheThirdPage = this.Request.query.page === 3 && this.Request.query.limit === 10
      if (isTheThirdPage) {
        this.modifyRequest({ key: 'query', value: { page: 2, limit: 20 } })
      }
      this.fetchData({ requestType: NEXT_PAGE })

      // 初始化断网重请求
      offlineHanler(this)
    },
    // 筛选
    updateQuery({ params }) {
      this.modifyRequest({ 
        key: 'Request', 
        value: {
          ...this.Request,
          query: {
            ...this.Request.query,
            ...params,
            limit: 10,
            page: 1,
          }
        }
      })
      this.cancelScrollExpand() // 取消滚动展开筛选(定义在filterBarMixins)
      this.updateQueryParams(params) // 更新query参数(定义在filterBarMixins)
      // 筛选 排序即隐藏搜索优惠券
      this.setPageState({
        hasHandleFilterBar: true,
      })
      abortRequest()
      this.fetchData({ requestType: REFRESH })
    },
    // 数据请求 
    async fetchData({ requestType, delayResolveData }) {
      return await this.mutex.runExclusive(async () => {

        try {
          this.logicBeforeRequest(requestType)
          const newData = await requestProductListApi({ 
            requestInfo: this.Request, 
            newSrcTabPageId: this.newSrcTabPageId, 
            toRoute: this.$route,
            customRequestQueue: {
              // 筛选相关组件，vuex 初始化提前渲染， 商品列表数据等待商品接口返回
              ...filterDataQueue(this)
            }
          })
          return this.logicAfterRequest(newData, delayResolveData)
        } catch(e) {
          this.closePageLoading()
          console.error('fetchDataError:', e)
        }

      })
    },
    // 请求前的逻辑
    logicBeforeRequest(requestType) {
      this.modifyRequest({ key: 'type', value: requestType })

      // 性能打点
      setMarkPoint(this.curRequestType.isFirstLoad, 'waitPageData')
      setMarkPoint(this.curRequestType.isFirstLoad, 'pageWaitInit')

      // 首次 需要跑的逻辑
      if (this.curRequestType.isFirstLoad) {
        this.setLoadingStatus() // 设置 page loading和骨架屏
      }

      // 首次 或者 筛选 需要跑的逻辑
      if (this.curRequestType.isFirstLoad || this.curRequestType.isRefresh) {
        // 点刷清空已经曝光的商品(refreshListStrategy中定义)
        this.resetExposeGoods()
        // 清除已经使用过的(cardRecommendMixin.js中定义)
        this.$refs.ProductList?.clearUseGooIds() 
        this.PageState.landingLock = true
      }

      // 设置 page loading和骨架屏
      // this.setLoadingStatus()
    },
    // 数据响应后的逻辑
    async logicAfterRequest(newData, delayResolveData) {
      // 性能打点
      setMarkPoint(this.curRequestType.isFirstLoad, 'apiResponseGoods')
      setMarkPoint(this.curRequestType.isFirstLoad, 'pageDataReady')
      
      // 无返回重置状态(cancel请求时，会触发这个逻辑)
      if (!newData || Object.keys(newData).length === 0) {
        return this.closePageLoading()
      }
      
      this.handleDefaultInitData(newData)

      // 性能打点
      setMarkPoint(this.curRequestType.isFirstLoad, 'setPageData')

      if (delayResolveData) {
        return newData
      } else {
        await this.resolveData(newData)
      }
    },
    // xhr和ssr 商品数据处理
    async resolveData(newData) {
      this.showPageRenderDone = true
      // bff字段适配
      this.initAdapter(newData).adaGoodsInitData()

      // 多孩标签云选中和重新请求逻辑
      if(handleKidsCloudTag(this, { newData })) return
      // 搜索直达词重定向 & 记录历史记录 search only
      if (doSearchPreprocess(this, { newData })) return
      // 关闭页面loading
      this.closePageLoading()
      // 商品相关数据在vuex初始化
      this.initProductDataNew(newData)

      //------------- 下面开始，非ssr需要跑的逻辑 -------------
      if (isServer()) return

      const tasks = this.fetchComponentAsyncData()

      // 设置用户行为自定义数据
      setUserActionsCustomData(this, { newData })

      // 首次 需要跑的逻辑
      if (this.curRequestType.isFirstLoad) {
        this.$store.commit('changeSeo', this.cccSeo, { root: true })
        // 如果是 SPA 跳转, 并且 ListAnalytics 有值, 需要将其重置为 null
        // 避免后面上报 expose_goods_list 出现时序问题
        // 解决useAnalysis.js中refreshAnalysisData方法中initAnalysis的执行，晚于initListExposeAnalysis
        this.ListAnalytics = null

        // 业务监控 - 页面打开成功率
        monitorPageOpen(this, { newData })
      }

      // 首次 或者 筛选 需要跑的逻辑
      if (this.curRequestType.isFirstLoad || this.curRequestType.isRefresh) {
        // 埋点初始化 - S
        this.initAdapter(newData).adpatSearchResultInterfaceCode()
        this.searchRequestStatus = newData.searchResultInterfaceCode
        refreshAnalysisData(this)
        // 埋点初始化 - E
        this.initFilterBarStyle({ isFilter: this.curRequestType.isRefresh }) // 重置筛选组件，定义在filterBarMixins
        this.queryTs = Date.now().toString() // 记录非翻页请求的时间戳 abt interleaving需要 给埋点用
      }

      // 性能打点
      setMarkPoint(this.curRequestType.isFirstLoad, 'pageResolveData')
      nextTick(() => {
        setMarkPoint(this.curRequestType.isFirstLoad, 'setPageDataNextTick')
      })

      // 商品全部加载后的逻辑
      await this.logicAfterGoodsLoaded(newData, tasks)
    },
    // bff字段适配
    initAdapter(newData) {
      const query = isServer() ? Object.assign({}, 
        this.$route.query,
        this.Request.query
      ) : this.Request.query
      
      return new BffAdapter({ 
        data: newData, 
        requestType: this.Request.type, 
        query, 
        route: this.$route,
        constantData: this.constantData,
        lastListData: {
          catInfo: this.catInfo,
        },
      })
    },
    // 商品全部加载后的逻辑
    logicAfterGoodsLoaded(newData, tasks) {
      // this.goodsLoading = true
      return new Promise((resolve) => {
        appEventCenter.once('goodsLoadedOnPageList', async () => {
          // this.goodsLoading = false
          this.modifyRequest({ key: 'type', value: '' })
          resolve()

          this.getBuyBoxPrices(newData.goods, this.sheinClubInfo) // 更新buybox价格

          await nextTick()

          // 首次 需要跑的逻辑
          if (this.curRequestType.isFirstLoad) {
            setSilInMeta(this) // 储存 Sil 信息到路由元 search only
          }
          
          // 插坑数据和搜索无结果热词
          await tasks
          // 设置商品购物车加车数量
          await checkoutGoodsInfo(this)

          // 初始化曝光（商品，筛选）
          initExposeAnalysis(this, { newData })

          // 商品数小于9个 且 还有分页数据时，需要再请求下一页商品数据
          const isLoadMore = await this.continueLoadMore(newData)
          if (isLoadMore) return

          // 请求上位词列表 search only
          handleHypernymFetch(this, { newData })
        })
      })
    },
    async continueLoadMore(newData) {
      const { limit } = newData.cat_info || {}
      if (this.goods.length < 9 && !this.noMoreProductsData) {
        await nextTick()
        this.modifyRequest({ key: 'query', value: { limit: +limit } })
        if (this.PageState.pageleave) { // 当前状态为离开页面时，page累加，type设置为nextpage，回来时再请求
          this.modifyRequest({ key: 'query', value: { page: this.Request.query.page + 1 } })
          this.modifyRequest({ key: 'type', value: NEXT_PAGE })
        } else { // 否则直接请求下一页
          this.loadMore()
          return true
        }
      } else {
        this.PageState.landingLock = false
      }
    },
    // 请求+数据处理 - E
    
    // 商品列表事件 - S
    handleClickGoods(item) {
      // search only
      if (this.ComponentState.SearchFeedback) {
        this.ComponentState.SearchFeedback.lock = true
      }

      clickGoods(this, { goodsItem: item })
    },
    handleOperateInRecPopup() {
      handleOperateInRecPopup(this)
    },
    handleOneClickPayComplete() {
      handleOneClickPayComplete(this)
    },
    async handleQuickAddSuccess({ goods_sn, index, addSuccess }) {
      handleCheckoutBtn(this, { goods_sn, index, addSuccess })
    },
    handleClickStartAddQuick(item) {
      handleClickStartAddQuick(this, { goodsItem: item })
    },
    // 商品列表事件 - E
    
    // 埋点相关逻辑 - S
    onSubscribeExpose(event) {
      onSubscribeExpose(this, event)
    },
    updateNewSrcTabPageId() {
      this.newSrcTabPageId = window.getSaPageInfo?.tab_page_id
    },
    // 埋点相关逻辑 - E

    // 设置 page loading和骨架屏
    setLoadingStatus() {
      this.PageState.searchPageLoading = true
      this.changeRootSLoadingStatus({
        show: true,
        type: 'newpage',
        containerCoverStyle: {
          'visibility': 'hidden'
        },
        curPageMaskTopBoundSelector: '',
        curPageScrollFix: true
      })
    },
    // 重置loading
    closePageLoading() {
      this.PageState.searchPageLoading = false
      this.PageState.skeletonLoading = false

      this.changeRootSLoadingStatus({
        type: '',
        show: false,
        containerCoverStyle: {},
        curPageMaskTopBoundSelector: '.j-common-logo-header',
        curPageScrollFix: true, // 当前加载 禁止其他滚动
        maskTransparent: false, // 蒙层透明 默认不透明
      })

      if (isServer()) return

      // 当SPA进入时 需要手动更新下吸顶项的几个副作用dom
      if (this.PageState.isSpaPage) {
        nextTick(() => this.updateScrollStickyEffectDom())
      }
    },
    handleDefaultInitData(newData) {
      if (!newData.sheinClubInfo) {
        newData.sheinClubInfo = this.sheinClubInfo
      }
      if (!newData.language) {
        newData.language = this.language
      }
    },

    // 上位词 - S
    // 上位词翻页
    loadMoreHypernymGoods() {
      fetchHypernyData(this, { requestType: 'nextpage' })
    },
    // 上位词初始化曝光
    initHypernymListExpose() {
      initHypernymListExpose(this)
    },
    // 丢车
    dropCart(target) {
      this.$refs?.ProductList?.injectsDropCart(target)
    },
    // 上位词 - E

    // 注册事件
    handleEventOn() {
      // 刷新推荐的商品总数
      appEventCenter?.on('recommendListPageNum', val => {
        if(!this.isStoreSearch) {
          updatePageData(this, { 
            recommend_count: isNumber(val) ? val : '-'  
          })
        }
      })
      onClickProductCardInGoodsDetail((args) => {
        window.sessionStorage?.setItem('userClickProductLatest', JSON.stringify(args))
      })
    },
    handleFromCart() {
      this.$refs.ProductList?.handleFromCart()
    },
    handleEnterProductList() {
      this.$refs.ProductList?.addExpandQuickCartClass()
    },
    handleLeaveProductList() {
      this.$refs.ProductList?.clearExpandQuickCartClass()
    }
  }
})
</script>
<style lang="less">
.search-product-list {
  position: relative;
  background: #f6f6f6;
  padding-bottom: 0.2rem;
  .list-skeleton-container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}
</style>
