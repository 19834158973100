// 说明：用户行为自定义数据

import { setUserActionTrackerCustomData, getUserActionTrackerOriginData } from 'public/src/pages/common/userActionTracker/core/index.js'

const FEATURE_KEY_DETAILS_RECOMMEND = 'detailsRecommend'
const MAX_SEARCH_GOODSIDS_LENGTH = 20

const setDetailsRecommendCustomData = (customData) => {
  setUserActionTrackerCustomData( 
    FEATURE_KEY_DETAILS_RECOMMEND,
    customData
  )
}

const initDetailsRecommendCustomData = ({ goods, data_c }) => {
  setDetailsRecommendCustomData({
    searchGoods: { 
      goodsIds: goods.map(item => item.goods_id).slice(0, MAX_SEARCH_GOODSIDS_LENGTH), 
      timestamp: Date.now() 
    },
    rt_f_search_unrelated_goods: {
      value: data_c?.search?.search_unrelated_goods || '',
      timestamp: Date.now()
    }
  })
}

const setUserActionsCustomData = (vm, { newData }) => {
  const goods = newData?.goods?.length ? newData.goods : []
  const data_c = newData?.bffProductsInfo?.data_c || {}

  // if (!goods.length) return

  // 首次加载
  if (vm.curRequestType.isFirstLoad) {
    initDetailsRecommendCustomData({ goods, data_c })
  }

  // 翻页
  if (vm.curRequestType.isNextPage) {
    const { collectData } = getUserActionTrackerOriginData(FEATURE_KEY_DETAILS_RECOMMEND, { isCurrentSession: true })
    const customData = {
      rt_f_search_unrelated_goods: {
        value: data_c?.search?.search_unrelated_goods || '',
        timestamp: Date.now()
      }
    }
    const lastSearchGoodsIds = collectData.customData?.searchGoods?.goodsIds || []
    const lastSearchGoodsIdsLength = lastSearchGoodsIds?.length
    if (lastSearchGoodsIdsLength < MAX_SEARCH_GOODSIDS_LENGTH) {
      Object.assign(customData, {
        searchGoods: { 
          goodsIds: [
            ...lastSearchGoodsIds, // firstLoad存的商品goodsId
            ...goods.map(item => item.goods_id).slice(0, MAX_SEARCH_GOODSIDS_LENGTH - lastSearchGoodsIdsLength) // 不足20个商品时，补足20个
          ],
          timestamp: Date.now() 
        }
      })
    }
    setDetailsRecommendCustomData(customData)
  }

  // 筛选
  if (vm.curRequestType.isRefresh) {
    initDetailsRecommendCustomData({ goods, data_c })
  }
}

export {
  setUserActionsCustomData
}
