import { NEXT_PAGE } from './useConstant'

const offlineHanler = (vm) => {
  if (typeof window === 'undefined' || navigator.onLine) return 

  vm.onLineListened = true
  const handler = () => {
    vm.onLineListened = false
    vm.fetchData({ requestType: NEXT_PAGE })
    window.removeEventListener('online', handler)
  }
  window.addEventListener('online', handler)
}

export {
  offlineHanler
}
