import { nextTick } from 'vue'
import checkOneClickPayQualification from '../../product_list_v2/js/checkOneClickPayQualification'

// 点击商品
const clickGoods = (vm, { goodsItem }) => {
  // 点刷逻辑request_early 发送请求并且保存 promise（定义在refreshListStrategy）
  if (vm.getClickRefreshAbt('request_early')) {
    vm.getRefreshListPromise = vm.triggerComeBackRefreshList()
  }

  vm.curClickItem = goodsItem // 存住当前点击的商品(不需要再data中声明)，为了解决二次请求的情况

  // 点推数据请求
  vm.insertRecommendGoodFun(goodsItem, vm.sheinClubInfo?.isPaid)
}

// 在实时反馈弹窗内有操作行为
const handleOperateInRecPopup = (vm) => {
  vm.isOperateInRecPopup = true

  nextTick(() => {
    vm.isOperateInRecPopup = false // 状态重置
  })
}

const handleOneClickPayComplete = (vm) => {
  checkOneClickPayQualification().then(qualificationInfo => {
    if (!qualificationInfo?.billno || !qualificationInfo?.support_one_click_pay) {
      vm.setBannerTagState({
        showTag: 0,
      })
    }
  })
}

// 点击加车按钮
const handleClickStartAddQuick = (vm, { goodsItem }) => {
  vm.curClickItem = goodsItem // 存住当前点击的商品(不需要再data中声明)，为了解决二次请求的情况

  // 点推数据请求
  vm.insertRecommendGoodFun(goodsItem, vm.sheinClubInfo?.isPaid)
}

export {
  clickGoods,
  handleOperateInRecPopup,
  handleOneClickPayComplete,
  handleClickStartAddQuick
}
