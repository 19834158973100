import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import { storeModuleName } from '@/public/src/pages/product_app/store/modules/search-product-list/index.js'
import storeMixins from '@/public/src/pages/product_list_v2/js/storeMixins'

export default {
  data() {
    return {
      hasRecContent: false,
    }
  },
  components: {
    // 考虑到以后再此上进行扩展，所以像NoResult一样把一些信息给注入进入了
    StoreNoResult: defineAsyncComponent(async () => storeMixins((await import(/* webpackChunkName: "sl_StoreNoResult" */'../../product_list_v2/components/StoreNoResult.vue')).default, {
      ...mapState(storeModuleName, [ 'PageState']),
      ...mapGetters(storeModuleName, ['locals', 'language', 'listAbtResult', 'catInfo']),
    })),
    NoResult: defineAsyncComponent(async () => storeMixins((await import(/* webpackChunkName: "sl_NoResult" */'../../product_list_v2/components/NoResult.vue')).default, {
      ...mapState(storeModuleName, [ 'PageState']),
      ...mapGetters(storeModuleName, ['locals', 'language']),
    })),
  },
  computed: {
    // 无结果 v.趋势搜索结果页屏蔽该功能
    showNoResult() {
      const mainGoodsNoResult = !this.goods.length && this.ComponentState.NoResult
      const hypernymProductSum = (this.hypernymProductInfo.sum && this.hypernymProductInfo.list.length) ?? 0
      const notMainSearchPage = ['store', 'brand', 'pageTrend'].includes(this.catInfo.search_type) // 店铺，专题，趋势搜索页

      return !!(notMainSearchPage
        ? mainGoodsNoResult
        : mainGoodsNoResult && hypernymProductSum === 0)
    },
    // 店铺搜索结果页 新空态样式
    isNewStoreNoResult() {
      const { store_code, search_type } = this.$route.query // 店铺查询
      let abtVal = this.listAbtResult?.storesearchefficiencyoptimize?.p?.storesearchefficiencyoptimize || 'old'
      // 店铺，且命中对应的实验 且 shein
      if (search_type == 'store' && store_code && ['new1', 'new2'].includes(abtVal)) {
        return true
      } 
      return false
    },
  },
  methods: {
    // 列表内搜索无结果全局搜入口
    searchInAll() {
      delete this.$route.meta.silInfo
      const remove = {
        sil_type: undefined,
        list_cat_id: undefined,
        list_cat_name: undefined,
      }
      this.updateQuery({ params: remove })
      // 先测试 refresh 有词更新问题再换 firstload
      // const query = {
      //   ...this.$route.query,
      //   ...remove,
      // }
      // this.$router.replace({ query })
    },
  }
}
