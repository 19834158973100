import { isHitDefaultChild } from 'public/src/pages/components/FilterBar/utils/kids.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { FIRST_LOAD } from './useConstant'

const isNoProductsInFirstLoad = false // 首次加载无商品[记录第一次默认童装筛选，无商品状态]
const isDefaultKidToast = false // 是否默认童装toast弹出

const handleKidsCloudTag = (vm, { newData }) => {
  if (!vm.curRequestType.isFirstLoad) return

  // 默认kids且筛选无商品时，去除默认kids，重新请求
  const isHit = isHitDefaultChild(newData)

  if (!newData?.goods?.length && isHit) {
    // 中间层会将默认kids赋值给tag_ids/child_id
    vm.modifyRequest({
      type: 'query',
      value: { tag_ids: '', kid_id: '' }
    })
    vm.fetchData({ requestType: FIRST_LOAD })
    //提前进行 fsp 结算
    window.fspSDK.terminateManually?.() 
    analysis.exposeKidsCollectionEntranceSelectedNull()
    vm.isNoProductsInFirstLoad = true

    return true
  } else if (isHit){
    vm.isDefaultKidToast = true
    vm.Request.query.tag_ids = vm.queryParams.tag_ids
    vm.Request.query.kid_id = vm.queryParams.kid_id
    analysis.exposeKidsCollectionEntranceSelected()
  }
}

const resetKidsCloudTagsVariable = (vm) => {
  vm.isDefaultKidToast = false
  vm.isNoProductsInFirstLoad = false
}

export {
  isNoProductsInFirstLoad,
  isDefaultKidToast,
  handleKidsCloudTag,
  resetKidsCloudTagsVariable,
}
